<template>
  <SummaryReports></SummaryReports>
</template>

<script>
import SummaryReports from "@/components/reports/SummaryReports";
export default {
  name: "Summary",
  components: { SummaryReports }
};
</script>

<style scoped></style>
