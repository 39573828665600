<template>
  <Page title="Summary Reports">
    <v-form @submit.prevent="">
      <v-row>
        <v-col>
          <v-select
            :items="feed_classes"
            label="Feed Category"
            no-data-text="No feed categories to select from"
            item-text="title"
            item-value="id"
            v-model="options.params.feed_class"
            clearable
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="filtered_feed_types"
            label="Feed Type"
            no-data-text="No feed types to select from"
            item-text="title"
            item-value="id"
            v-model="options.params.feed_type"
            clearable
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            :items="countries"
            label="Countries"
            no-data-text="No countries to select from"
            item-text="country_name"
            item-value="id"
            v-model="options.params.country"
            clearable
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="filtered_regions"
            label="Regions"
            no-data-text="No regions to select from"
            item-text="name"
            item-value="id"
            v-model="options.params.region"
            clearable
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="filtered_states"
            label="States/Provinces"
            no-data-text="No states/provinces to select from"
            item-text="title"
            item-value="id"
            v-model="options.params.state"
            clearable
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <DatePickerInput
            v-model="options.params.created_from"
            :datePickerConfig="date_from"
            @updateDateField="updateDateField"
          />
        </v-col>
        <v-col>
          <DatePickerInput
            v-model="options.params.created_to"
            :date-picker-config="date_to"
            @updateDateField="updateDateField"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="options.params.dry_matter_from"
            label="Dry Matter From"
            :rules="rules.between"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="options.params.dry_matter_to"
            label="Dry Matter To"
            :rules="rules.between"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="options.params.crude_protein_from"
            label="Crude Protein From"
            :rules="rules.between"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="options.params.crude_protein_to"
            label="Crude Protein To"
            :rules="rules.between"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="options.params.ndf_from"
            label="NDF From"
            :rules="rules.between"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="options.params.ndf_to"
            label="NDF To"
            :rules="rules.between"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="options.params.adf_ndf_from"
            label="ADF / NDF Ratio From"
            :rules="rules.between"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="options.params.adf_ndf_to"
            label="ADF / NDF Ratio To"
            :rules="rules.between"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="options.params.lignin_ndf_from"
            label="Lignin / NDF Ratio From"
            :rules="rules.between"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="options.params.lignin_ndf_to"
            label="Lignin / NDF Ratio To"
            :rules="rules.between"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            :items="capitalizedUnitTypes"
            label="Unit Type"
            no-data-text="No unit types to select from"
            item-text="name"
            item-value="id"
            v-model="options.params.unit_type"
            clearable
          ></v-select>
        </v-col>
        <v-col class="text-center">
          <a @click="triggerDownloadSummaryReport()" target="_blank"
            ><v-btn color="primary">Download Summary Report</v-btn></a
          >
        </v-col>
      </v-row>
    </v-form>
  </Page>
</template>

<script>
import Page from "@/components/layout/Page";
import { mapActions, mapGetters, mapMutations } from "vuex";
import DatePickerInput from "@/components/formElements/DatePickerInput";
import rules from "@/utils/rules";
import { getBaseUrl } from "@/utils";

const capitalize = s => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export default {
  name: "SummaryReports",
  components: { Page, DatePickerInput },
  data() {
    return {
      loading: true,
      rules: {
        between: [rules.betweenNums(0, 100)]
      },
      options: {
        params: {
          feed_class: "",
          feed_type: "",
          country: "",
          region: "",
          state: "",
          created_from: "",
          created_to: "",
          dry_matter_from: "",
          dry_matter_to: "",
          crude_protein_from: "",
          crude_protein_to: "",
          ndf_from: "",
          ndf_to: "",
          unit_type: ""
        }
      },
      date_from: {
        label: "Date From",
        field: "created_from"
      },
      date_to: {
        label: "Date To",
        field: "created_to"
      }
    };
  },
  computed: {
    ...mapGetters("baseData", [
      "feed_classes",
      "feed_types",
      "countries",
      "regions",
      "states",
      "unit_types"
    ]),
    filtered_feed_types() {
      if (this.options.params.feed_class) {
        return this.feed_types.filter(
          item => item.feed_class_id === this.options.params.feed_class
        );
      } else {
        return this.feed_types;
      }
    },
    filtered_regions() {
      if (this.options.params.country) {
        return this.regions.filter(
          item => item.country_id === this.options.params.country
        );
      } else {
        return [];
      }
    },
    filtered_states() {
      if (this.options.params.region) {
        const region = this.regions.find(
          item => item.id === this.options.params.region
        );
        return region.states;
      } else if (this.options.params.country) {
        return this.states.filter(
          item => item.country_id === this.options.params.country
        );
      } else {
        return [];
      }
    },
    capitalizedUnitTypes() {
      return this.unit_types.map(item => {
        item.name = capitalize(item.name);
        return item;
      });
    },
    summaryReportUrl() {
      const queryString = Object.keys(this.options.params)
        .filter(key => this.options.params[key])
        .map(key => key + "=" + this.options.params[key])
        .join("&");
      return `${getBaseUrl()}/reports/summary?${queryString}`;
    }
  },
  methods: {
    ...mapActions("baseData", [
      "getFeedClasses",
      "getFeedTypes",
      "getCountries",
      "getRegions",
      "getStates",
      "getUnitTypes"
    ]),
    ...mapActions("reports", ["downloadSummaryReport"]),
    ...mapMutations(["setLoading"]),
    triggerDownloadSummaryReport() {
      this.setLoading({
        message: "Downloading Report...",
        opacity: 0.5,
        overlay: true
      });
      this.downloadSummaryReport({
        url: this.summaryReportUrl
      }).then(() => this.setLoading({}));
    },
    updateDateField(field, value) {
      this.options.params[field] = value;
    }
  },
  mounted() {
    this.loading = true;
    Promise.all([
      this.getCountries(),
      this.getRegions(),
      this.getStates(),
      this.getFeedClasses(),
      this.getFeedTypes(),
      this.getUnitTypes()
    ]).then(() => (this.loading = false));
  }
};
</script>

<style scoped></style>
